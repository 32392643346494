import { useLocation } from "@remix-run/react";
import React from "react";
import { Navbar as BootstrapNavbar, Container, Nav } from "react-bootstrap";

export const Navbar: React.FC<{
  title: string;
  links: Record<string, string>;
}> = ({ title, links }) => {
  const location = useLocation();
  return (
    <BootstrapNavbar
      expand="lg"
      className="bg-black"
      sticky="top"
      bg="dark"
      data-bs-theme="dark"
    >
      <Container className="justify-between">
        <BootstrapNavbar.Brand href="/">{title}</BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="navbar-nav" />
        <BootstrapNavbar.Collapse id="navbar-nav">
          <Nav className="ms-auto justify-end">
            {Object.entries(links).map(([label, url]) => (
              <Nav.Link
                active={location.pathname === url}
                key={label}
                href={url}
              >
                {label}
              </Nav.Link>
            ))}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};
